.content-cofweddings {
  overflow: hidden;
  
  .page-header-container {
    .subtitle {
      line-height: 1.6rem;
    }
  }

  .mobile-ss {
    position: absolute;
    box-shadow: -10px 2px 5px 0 rgba(0,0,0,0.2);
    width: 190px;
    height: auto;
    top: 79px;
    left: 357px;

    @media (max-width: $screen-sm) {
      width: 35%;
      top: 44px;
      left: inherit;
      right: 0;
    }
  }

  .tablet-ss {
    width: 100%;
    max-width: 480px;
    height: auto;

    @media (max-width: $screen-sm) {
      width: 80%;
    }
  }
}

.content-branding {
  background: #F9F9F9;
}