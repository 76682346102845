.content-abc,
.content-smart-roster {
  .glide__slide,
  .glide__bullet img {
    border: solid 1px #EAEAEA;
  }
}

.content-smart-roster {
  background: #F9F9F9;
  width: 100%;
  text-align: center;
}

.content-component-library {
  img {
    width: 100%;
    height: auto;
  }
}

.comp-concepts-container {
  margin: 15rem 0 0;

  > .row:not(:last-child) {
    margin: 7.5rem 0;
    padding: 7.5rem 0;
  }

  .concept2_blue {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 60%;
  }

  .concept2_green {
    box-shadow: -10px 0px 10px -8px rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 70%;
    margin-left: 10%;
  }

  .concept2_pink {
    box-shadow: -10px 0px 10px -8px rgba(0, 0, 0, 0.25);
    position: relative;
    width: 80%;
    margin-left: 20%;
    left: 0;
  }





  .concept1_modal_success,
  .concept1_modal_confirm {
    position: absolute;
    left: 0;
    top: -6px;
  }

  .concept1_alerts {
    position: absolute;
    bottom: -6px;
    left: 0;
  }
}