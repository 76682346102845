footer {
  padding: 15rem 0;

  h1 {
    font-family: Lusitana;
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    margin: 0 0 2.4rem;
  }

  .job-title {
    margin-bottom: 2.4rem;
    display: block;
    text-align: center;
    font-family: 'nexaBold';
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
  }
  
  .social-icons {
    margin-top: 2.4rem;
    text-align: center;

    i {
      font-size: 3.2rem;
      margin: 0 12px;
    }
  }
}