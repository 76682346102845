$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;


a {
  color: map-get($theme-colors, 'tertiary');
  
  &:hover {
    color: map-get($theme-colors, 'tertiary');
  }
}

hr {
  border: 0;
  background: rgba(map-get($theme-colors, 'primary'), 0.4);
  height: 1px;
  width: 200px;
  margin: auto;
}

button {
  // @extend .txt-secondary;
  // font-size: 1.4em;
  // letter-spacing: 0.1875em;

  display: block;
  background: map-get($theme-colors, 'primary');
  margin-bottom: 80px;
  padding: 0 32px;
  height: 48px;
  
  color: white;
  border: 0;
  border-radius: 5px;
}

i {
  font-size: 3.2em;
}

.content-block {
  padding: 15rem 0;

  @media (max-width: $screen-md) {
    padding: 7.5rem 0;
  }

  .row {
    position: relative;
  }

  img {
    width: 100%;
    height: auto;
  }
}

// #my-work {
//   padding: 0 15px;
// }

.container {
  width: 100%;
  max-width: 1140px;
}

.split-container {
  margin-top: 15rem;

  .challenges-container {
    max-width: 340px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;

    @media (max-width: $screen-sm) {
      margin-top: 3rem;
      border: 0;
    }
  }
}






////////// * MASONRY NAV * //////////

#hero {
  // right: 80px;
  // margin-top: -189px;
  // position: absolute;
  // z-index: 100;
  // top: 50%;

  color: map-get($theme-colors, 'primary');
  // background: map-get($theme-colors, 'secondary');
  padding: 60px;
  margin: 5px;

  button {
    display: block;
    margin: 80px 0 0;
  }
}

#gradient_panel {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  pointer-events: none;
  // background: linear-gradient(180deg, rgba(map-get($theme-colors, 'bgGradientPrimary'), 0.90) 0%, rgba(map-get($theme-colors, 'bgGradientPrimary'), 0) 100%);
}

.section-title {
  margin: 0;
  font-family: 'nexaBold';
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-transform: uppercase;
}

.dotted-line {
  width: 100%;
  height: 1px;
  overflow: hidden;
  background: none;
  margin: 40px 0;

  &:after {
    opacity: 0.3;
    content: '';
    display: block;
    border-top: dashed 2px map-get($theme-colors, 'primary');
  }
}






.xblock {
  cursor: pointer;
  
  &:not([data-extra-class]) {
    padding: 0;
  }

  .img-crop {
    position: relative;
    overflow: hidden;
    // background-color: map-get($theme-colors, 'secondary');
  }

  img {
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: auto;
  }

  .layered {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    
    opacity: 0;
    transition: opacity 500ms;
  }
  
  .fancy-effect {
    mix-blend-mode: screen;
    -webkit-filter: grayscale(100%) contrast(100%);
    filter: grayscale(100%) contrast(100%);

    &.overlay-blend {
      mix-blend-mode: overlay;
    }
  }

  &:hover {
    .layered {
      opacity: 1;
      transition: opacity 500ms;
    }
  }

  .card-flip {
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
  }

  .card-flip-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    animation: flip-animation 12s infinite ease-out;
    
    width: 100%;
    padding-top: 120%;
  }

  .card-front, .card-back {
    position: absolute;
    backface-visibility: hidden;
    padding: 0;
    left: 0;
    top: 0;
    opacity: 1;
  }

  .card-front {
    transform: rotateY(180deg);
  }



  .card-fade {
    .card-uploading {
      animation: fade-animation 10s infinite ease-in-out;
      padding: 20px 0 20px 20px;
    }
  
    .card-upload {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 1;
      padding: 20px 0 20px 20px;
    }
  }



  .invision-logo {
    padding: 100px;
  }

  .sobel-logo {
    padding: 0px;
  }

  .mgm-logo {
    padding: 0px;
  }
}

@keyframes flip-animation {
  0% {
    transform: rotateY(180deg);
  }
  78% {
    transform: rotateY(180deg);
  }
  80% {
    transform: rotateY(360deg);
  }
  98% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes fade-animation {
  0% {
    opacity: 0;
  }
  78% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}





////////// * TILES * //////////
.yourlvtherapist-logo {
  box-sizing: border-box;
  text-align: center;

  img.logo {
    background: map-get($theme-colors, 'tertiary');
    padding: 25% 25% 35%;
  }

  img.dots {
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    height: auto;
    margin: 0;
  }
}







////////// * SLIDER * //////////

.glide__slides {
  margin: 0 0 60px;

  @media (max-width: $screen-sm) {
    margin: 0 0 15px;
  }
}

.glide__slide,
.glide__bullet img {
  border: solid 1px #EAEAEA;
}

.glide__bullets {
  position: relative;
  z-index: inherit;
  bottom: 0;
  left: 0;
  transform: inherit;
}

.glide__bullet {
  width: auto;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0 15px;
  border-radius: 0;
  box-shadow: none;

  @media (max-width: $screen-sm) {
    padding: 0 5px;
  }
}





.slide-blurb {
  font-size: 1.6rem;
  line-height: 2.8rem;
  max-width: 700px;
  margin: 0 auto 7.5rem;
}