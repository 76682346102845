.page-header-container {
  margin-bottom: 7.5rem;

  h2 {
    font-family: Lusitana;
    font-size: 4.8rem;
    line-height: 6.2rem;
    text-align: center;
    margin-bottom: 24px;
    letter-spacing: 0;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 50px;
    }

    @media (max-width: $screen-sm) {
      margin: 6rem 0 3rem;
      display: block;
    }
    
    span {
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-transform: uppercase;

      &.subtitle-skill {
        display: inline-block;
        padding: 0 15px;
        font-family: 'nexaBold';
        border-right: solid 1px map-get($theme-colors, 'primary');

        @media (max-width: $screen-sm) {
          border: 0;
          line-height: 3rem;
          display: block;
        }
      }

      &.subtitle-link {
        display: inline-block;
        padding: 0 15px;
        &.asterisk {
          position: relative;

          &:after {
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 0.8rem;
            line-height: 0.9rem;
            content: '\F069';
            top: 3px;
            right: 0;
          }
        }

        @media (max-width: $screen-sm) {
          border: 0;
          line-height: 3rem;
        }
      }
    }
  }

  .tech-used {
    font-size: 1.6rem;
    line-height: 2.8rem;
    max-width: 700px;
    margin: 50px auto 0;
    text-align: center;
  }
}