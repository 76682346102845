.content-laguna {
  overflow: hidden;
  
  .mobile-ss {
    max-width: 460px;
    margin: 0 auto;

    @media (max-width: $screen-sm) {
      margin: 0 auto;
      display: block;
    }
  }
}