.sidebar-container {
  background: white;
  width: 320px;
  border-top: solid 1px map-get($theme-colors, 'midGrey');
  border-bottom: solid 1px map-get($theme-colors, 'midGrey');
  padding: 4rem 3rem 0;
  position: absolute;
  z-index: 100;
  top: 8rem;

  @media (max-width: $screen-sm) {
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
    top: 0;
    left: 0 !important;
    right: 0 !important;
    border-top: 0;
    padding: 4rem 15%;
    box-shadow: -10px 20px 10px 0 rgba(0, 0, 0, 0.05);
  }

  &.left {
    left: -3rem;
    box-shadow: 10px 0 10px 0 rgba(0,0,0,0.05);
  }

  &.right {
    right: -3rem;
    box-shadow: -10px 0 10px 0 rgba(0,0,0,0.05);
  }

  .disclaimer {
    font-family: Lusitana;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0;
    color: map-get($theme-colors, 'primary');

    &.asterisk {
      position: relative;

      &:before {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 0.8rem;
        line-height: 0.9rem;
        content: '\F069';
        top: 5px;
        left: -14px;
      }
    }
  }
  
  .dotted-line {
    width: 100%;
    height: 1px;
    overflow: hidden;
    background: none;
    margin: 40px 0;

    &:after {
      opacity: 0.3;
      content: '';
      display: block;
      border-top: dashed 2px map-get($theme-colors, 'primary');
    }
  }

  .section-title {
    margin: 0 0 2rem;
  }

  .description {
    margin: 0 0 40px;
    font-family: 'nexaLight';
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .link {
    margin: 0 0 40px;
    line-height: normal;

    .link-title {
      margin: 0;
      font-family: 'nexaBold';
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-transform: uppercase;
    }
    .link-url {
      margin: 0;
      font-family: 'nexaLight';
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}

