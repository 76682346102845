.content-hrs {
  .glide__slide, .glide__bullet img {
    border: solid 1px #EAEAEA;
  }

  .tablet-why-hrs {
    width: 50%;
    height: auto;
    margin: 0 auto;
    display: block;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  }

  .mobile-meet-team {
    width: 27%;
    height: auto;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: -1;
  }
  
  .mobile-case-study {
    width: 27%;
    height: auto;
    position: absolute;
    right: 0;
    top: 10%;
    z-index: -1;
  }
}
