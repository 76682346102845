.content-allegiant,
.content-aos {
  .glide__slide,
  .glide__bullet img {
    border: solid 1px #EAEAEA;
  }
}

.content-aos {
  background: #F9F9F9;
  width: 100%;
  text-align: center;

  .concept-container {
    background: white;
  }
}

.icons-container {
  img {
    width: 100%;
    height: auto;

    &.portal {
      float: left;
      width: 50%;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }

  text-align: center;
}
