.concept-container {
  background: map-get($theme-colors, 'lighterGrey');
  width: 100%;
  padding: 10rem 15px;
  text-align: center;
  margin: 15rem 0 0;

  @media (max-width: $screen-sm) {
    margin-top: 0;
  }

  .section-title {
    text-align: center;
    margin-top: 3.5rem;
  }

  .concept-txt {
    max-width: 70rem;
    margin: 2rem auto 4rem;
  }
}