.challenges-container {
  padding: 4rem 3rem;
  text-align: center;
  margin-top: 15rem;

  @media (max-width: $screen-md) {
    margin-top: 3rem;
  }

  .section-title {
    margin-bottom: 40px;
  }

  i {
    font-size: 4.8rem;
    margin-bottom: 3rem;
  }

  &.long {
    .challenge-box {
      padding-left: 30px;
      padding-right: 30px;
      
      @media (min-width: $screen-md) {
        &:not(:last-child) {
          border-right: solid 1px map-get($theme-colors, 'midGrey');
        }
      }
    }
  }

  &.stack {
    .challenge-box {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
