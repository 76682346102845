.content-influential-app {
  overflow: hidden;
  
  .watson-logo {
    height: 32px;
    width: auto;
    display: block;
    margin: 40px auto;
  }

  .challenges-container {
    margin-top: 160px;

    @media (max-width: $screen-md) {
      margin-top: 3rem;
    }
  }

  .mobile-ss-login {
    position: absolute;
    background: white;
    max-width: 300px;
    width: 50%;
    top: 80px;
  }

  .mobile-ss-dashboard {
    position: relative;
    background: white;
    max-width: 300px;
    width: 50%;
    margin: 0 auto;
    display: block;
    box-shadow: -3px 1px 15px 0 rgba(0, 0, 0, 0.10);
    left: -60px;
  }

  .mobile-ss-campaigns {
    position: absolute;
    background: white;
    max-width: 300px;
    width: 50%;
    right: 0;
    top: 60px;
    box-shadow: -11px 1px 11px 0 rgba(0, 0, 0, 0.05);
  }
}


.gallows-container {
  .gallows-mobile-1 {
    width: calc(241.64/1140*100%);
    height: auto;
    float: left;
  }

  .gallows-mobile-2 {
    width: calc(242.61/1140*100%);
    height: auto;
    float: left;
  }

  .gallows-desktop {
    width: calc(655.38/1140*100%);
    height: auto;
    float: left;
  }
}

.jott-container {
  .jott-mobile {
    width: calc(299.72/1140*100%);
    height: auto;
    float: left;
  }

  .jott-desktop {
    width: calc(839.13/1140*100%);
    height: auto;
    float: left;
  }
}

.un-container {
  margin-bottom: 3rem;

  .un-huffpost {
    width: 100%;
    height: auto;
    box-shadow: 5px -25px 20px -10px rgba(0, 0, 0, 0.15);

    @media (max-width: $screen-sm) {
      position: relative;
      width: 100%;
      top: 0;
    }
  }
}

.content-influential-campaigns {
  background: map-get($theme-colors, 'lighterGrey');

  .glide-camp {
    margin-top: 7.5rem;

    @media (max-width: $screen-sm) {
      margin-top: 3rem;
    }
  }

  // hr {
  //   margin-top: 7.5rem;
  // }
  
  .glide__slides {
    margin: 0 auto;
    width: auto;

    img {
      height: 100%;
    }
  }

  .gallows-container,
  .jott-container {
    padding-top: 7.5rem;
    margin-top: 15rem;
    text-align: center;

    @media (max-width: $screen-sm) {
      padding-top: 3rem;
      // margin-top: 0;
      overflow: hidden;
    }
  }
}