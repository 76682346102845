$theme-colors: (
  primary: #6C7384,
  secondary: #EFF3F6,
  tertiary: #112025,
  lighterGrey: #F9F9F9,
  midGrey: #B2B2B2,
  bgGradientPrimary: white,
  bgGradientSecondary: black
);

@mixin inner-shadow($inset, $x, $y, $blur, $spread, $color) {
  -webkit-box-shadow: $inset $x $y $blur $spread $color;
  -moz-box-shadow: $inset $x $y $blur $spread $color;
  box-shadow: $inset $x $y $blur $spread $color;
}




html {
  font-size: 62.5%;
}

body {
  // min-width: 1140px;
  margin: 0;
  padding: 0;
  font-family: "nexaLight", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: 0.175rem;

  color: map-get($theme-colors, 'primary');
  background-color: white;
  
}

.xmasonry {
  background: map-get($theme-colors, 'bgGradientSecondary');
  background: linear-gradient(to bottom, map-get($theme-colors, 'bgGradientSecondary') 0%, 
    map-get($theme-colors, 'bgGradientPrimary') 100%);
}

@font-face {
  font-family: 'nexaBold';
  src: url('./assets/fonts/nexa/nexa_bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/nexa/nexa_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nexaLight';
  src: url('./assets/fonts/nexa/nexa_light-webfont.woff2') format('woff2'),
       url('./assets/fonts/nexa/nexa_light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}


// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

@import './app.scss';
@import './footer.scss';

@import './pages/ABC/ABC.scss';
@import './pages/Allegiant/allegiant.scss';
@import './pages/COFWeddings/COFWeddings.scss';
@import './pages/HRS/HRS.scss';
@import './pages/Influential/influential.scss';
@import './pages/InVision/inVision.scss';
@import './pages/KaraUrieLott/karaUrieLott.scss';
@import './pages/MGMResorts/MGMResorts.scss';
@import './pages/NewBalance/newBalance.scss';
@import './pages/RanchLaguna/ranchLaguna.scss';
@import './pages/SobelWestex/sobelWestex.scss';

@import './components/Sidebar/sidebar.scss';
@import './components/Concept/concept.scss';
@import './components/Challenges/challenges.scss';
@import './components/PageHeader/pageHeader.scss';