.content-new-balance {
  overflow: hidden;
  
  .glide__slide,
  .glide__bullet img {
    border: solid 1px #EAEAEA;
  }

  .split-container {
    .right {
      .mobile-ss-warrior {
        width: 411px;
        float: right;
      }

      .mobile-ss-brine {
        float: right;
        position: absolute;
        max-height: 80%;
        width: auto;
        top: 10%;
        right: 320px;
        box-shadow: 10px 2px 20px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .left {
      margin: auto 0;
      text-align: center;

      .logo-container {
        margin: 20px 25px 40px;
        
        img {
          height: 100px;
          width: auto;
          margin: 20px;
        }
      }
    }
  }
}