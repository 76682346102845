.content-kara-urie-lott {
  overflow: hidden;
  
  .glide__slide,
  .glide__bullet img {
    border: solid 1px #EAEAEA;
  }

  .mobile-ss-resources {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: auto;
  }

  .tablet-ss-cms {
    position: relative;
    top: 40px;
    left: 120px; 
    box-shadow: -8px 0 10px 0 rgba(0, 0, 0, 0.15);
    width: calc(100% - 120px);
  }
}